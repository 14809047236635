import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import NavBar from "./views/NavBar/NavBar";
import Footer from "./views/Footer/Footer";
import Auth from "../hoc/auth";
import { Icon } from "antd";
import { ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import en from "antd/es/locale/en_US";
import fr from "antd/es/locale/fr_FR";
import ar from "antd/es/locale/ar_EG";
import useWindowResize from "./utils/BreakPoints.js";
import MobileBottomNavBar from "../components/views/NavBar/MobileBottomNavBar.js";
import AboutUs from "./views/AboutUs/AboutUs";


//null   Anyone Can go inside
//true   only logged in user can go inside
//false  logged in user can't go inside
const LandingPage = React.lazy(() =>
  import("./views/LandingPage/LandingPage.js")
);
const LoginPage = React.lazy(() => import("./views/LoginPage/LoginPage.js"));
const RegisterPage = React.lazy(() =>
  import("./views/RegisterPage/RegisterPage.js")
);
const UploadProductPage = React.lazy(() =>
  import("./views/UploadProductPage/UploadProductPage")
);
const DetailsProductPage = React.lazy(() =>
  import("./views/DetailsProductPage/DetailsProductPage")
);
const CartPage = React.lazy(() => import("./views/CartPage/CartPage"));
const HistoryPage = React.lazy(() => import("./views/HistoryPage/HistoryPage"));
const Profile = React.lazy(() => import("./views/Profile/Profile"));
const UserProfile = React.lazy(() => import("./views/Profile/UserProfile"));
const OrdersPage = React.lazy(() => import("./views/OrdersPage/OrdersPage"));
const RequestDelivery = React.lazy(() =>
  import("./views/OrdersPage/DeliveryTypes/RequestDelivery")
);
const ProducerDelivers = React.lazy(() =>
  import("./views/OrdersPage/DeliveryTypes/ProducerDelivers")
);
const MeetUpToDeliver = React.lazy(() =>
  import("./views/OrdersPage/DeliveryTypes/MeetUpToDeliver")
);
const ComeAndTakePackageAt = React.lazy(() =>
  import("./views/OrdersPage/DeliveryTypes/ComeAndTakePackageAt")
);
const NotificationsPage = React.lazy(() =>
  import("./views/Notifications/NotificationsPage")
);
const DeliveryDatesPage = React.lazy(() =>
  import("./views/DeliveryDates/DeliveryDatesPage")
);
const UploadDelivery = React.lazy(() =>
  import("./views/DeliveryPage/Sections/UploadDelivery")
);
const DeliveryPage = React.lazy(() =>
  import("./views/DeliveryPage/DeliveryPage")
);
const DetailsDeliveryPage = React.lazy(() =>
  import("./views/DetailsDeliveryPage/DetailsDeliveryPage")
);
const GoogleMapsDirections = React.lazy(() =>
  import("./views/DeliveryPage/Sections/GoogleMapsDirections.js")
);
const DeliveryProfile = React.lazy(() =>
  import("./views/Profile/DeliveryProfile.js")
);
const DashBoard = React.lazy(() => import("./views/DashBoard/DashBoard.js"));
const PageNotFound = React.lazy(() => import("./views/PageNotFound"));
const ActivateEmail = React.lazy(() =>
  import("./views/ActivateEmail/ActivateEmail")
);
const ForgotPsw = React.lazy(() => import("./views/ResetPassword/ForgotPsw"));
const EnterNewPsw = React.lazy(() =>
  import("./views/ResetPassword/EnterNewPsw")
);
const PrivacyPolicy = React.lazy(() =>
  import("./views/TremsAndConditionsandPrivacyPolicy/PrivacyPolicy")
);
const TermsAndConditions = React.lazy(() =>
  import("./views/TremsAndConditionsandPrivacyPolicy/TermsAndConditions")
);

function App() {
  const center = {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
    width: "100px",
    height: "100px",
    fontSize: "30px",
  };
  const device = useWindowResize();

  const { i18n } = useTranslation();
  return (
    <Suspense
      fallback={
        <div style={center}>
          <Icon type="loading" />
        </div>
      }
    >
      <ConfigProvider
        locale={i18n.language === "ar" ? ar : i18n.language === "fr" ? fr : en}
      >
        <NavBar />
        <div style={{ paddingTop: device === "mobile" ?"51px": "69px", minHeight: "calc(100vh - 80px)" }}>
          <Switch>
            <Route exact path="/" component={Auth(LandingPage, null)} />
            <Route exact path="/login" component={Auth(LoginPage, false)} />
            <Route
              exact
              path="/register"
              component={Auth(RegisterPage, false)}
            />
            <Route
              exact
              path="/product/upload"
              component={Auth(UploadProductPage, true)}
            />
            <Route
              exact
              path="/product/:productId"
              component={Auth(DetailsProductPage, null)}
            />
            <Route exact path="/user/cart" component={Auth(CartPage, true)} />
            <Route exact path="/history" component={Auth(HistoryPage, true)} />
            <Route exact path="/profile" component={Auth(Profile, true)} />
            <Route
              exact
              path="/user/:userId"
              component={Auth(UserProfile, null)}
            />
            <Route
              exact
              path="/delivery/upload"
              component={Auth(UploadDelivery, true)}
            />
            <Route
              exact
              path="/delivery"
              component={Auth(DeliveryPage, true)}
            />
            <Route
              exact
              path="/delivery/:deliveryId"
              component={Auth(DetailsDeliveryPage, true)}
            />
            <Route
              exact
              path="/GoogleMapsDirections"
              component={Auth(GoogleMapsDirections, true)}
            />
            <Route
              exact
              path="/deliveryProfile"
              component={Auth(DeliveryProfile, true)}
            />

            <Route exact path="/orders" component={Auth(OrdersPage, true)} />
            <Route
              exact
              path="/requestDelivery"
              component={Auth(RequestDelivery, true)}
            />
            <Route
              exact
              path="/producerDelivers"
              component={Auth(ProducerDelivers, true)}
            />
            <Route
              exact
              path="/meetUpToDeliver"
              component={Auth(MeetUpToDeliver, true)}
            />
            <Route
              exact
              path="/comeAndTakePackageAt"
              component={Auth(ComeAndTakePackageAt, true)}
            />
            <Route
              exact
              path="/notifications"
              component={Auth(NotificationsPage, true)}
            />
            <Route
              exact
              path="/deliveryDates"
              component={Auth(DeliveryDatesPage, true)}
            />
            <Route
              exact
              path="/dashboard"
              component={Auth(DashBoard, true, true)}
            />
            <Route
              exact
              path="/activate/:token"
              component={Auth(ActivateEmail, false)}
            />
            <Route
              exact
              path="/forgot-password"
              component={Auth(ForgotPsw, false)}
            />
            <Route
              exact
              path="/reset-password/:token"
              component={Auth(EnterNewPsw, false)}
            />
            <Route
              exact
              path="/privacy-policy"
              component={Auth(PrivacyPolicy, null)}
            />
            <Route
              exact
              path="/terms-and-conditions"
              component={Auth(TermsAndConditions, null)}
            />
            <Route exact path="/about-us" component={Auth(AboutUs, null)} />

            {/* <Route exact path='/advertisement'   component={Auth(AdvertisementPage, true)} /> */}
            {/* <Route exact path='/advertisement/upload'   component={Auth(UplaodAdvertisement, true)} /> */}
            <Route component={Auth(PageNotFound, null)} />
          </Switch>
        </div>
        <MobileBottomNavBar/>
        <Footer />
      </ConfigProvider>
    </Suspense>
  );
}

export default App;
